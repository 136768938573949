<template>
  <div>
    <div v-if="automations && automations.length > 0" class="d-flex flex-wrap">
      <v-card
        v-for="(rule, idx) in automations"
        :key="idx"
        class="px-2 mr-3 mb-2 mt-1 overflow-hidden rounded"
        width="320"
        height="120"
      >
        <p class="flash">
          <v-icon size="150" class="flash__icon">mdi-flash-outline</v-icon>
        </p>
        <v-card-title class=" py-0 mb-1 mt-2 px-0">
          <div class="live"></div>
        </v-card-title>
        <v-card-text class="py-0 px-2 ma-0">
          <p class="mb-2 text-truncate">
            <b class="mr-4">EVENT: </b> {{ rule.event }}
            <v-icon v-if="rule.event != 'contactCreated'"
              >mdi-arrow-right-bold-outline</v-icon
            >
            {{ rule.targetValue }}
          </p>
          <p class="mb-2 text-truncate">
            <b class="mr-1">ACTION: </b> {{ rule.action }}
            <v-icon>mdi-arrow-right-bold-outline</v-icon>
            <span
              v-if="
                rule.actionChange && rule.actionChange.hasOwnProperty('name')
              "
              >{{ rule.actionChange.name }}</span
            >
            <span
              v-else-if="
                rule.actionChange &&
                  rule.actionChange.hasOwnProperty('template')
              "
              >{{ rule.actionChange.template.name }}</span
            >

            <span
              v-else-if="
                rule.actionChange && rule.actionChange.hasOwnProperty('audio')
              "
              >{{ rule.actionChange.audio.fileName }}</span
            >
            <span v-else>{{
              rule.actionChange && rule.actionChange.url
                ? rule.actionChange.url
                : ""
            }}</span>
          </p>
        </v-card-text>
        <v-card-actions class="pa-0">
          <v-spacer></v-spacer>
          <v-btn small icon @click="deleteRule(rule.id)">
            <v-icon color="red">mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card
        class="px-2 py-5 mr-3 mb-2 mt-1 d-flex align-center justify-center rounded"
        width="320"
        height="120"
        @click="newRuleDialog = true"
      >
        <div>
          <div class="d-flex justify-center">
            <v-icon size="70" color="light-green lighten">
              mdi-plus
            </v-icon>
          </div>
          <p class="mb-2">Add a new Rule</p>
        </div>
      </v-card>
    </div>
    <div v-else class="d-flex flex-wrap">
      <v-card
        class="px-2 py-5 mr-3 mb-2 mt-1 d-flex align-center justify-center rounded"
        width="320"
        height="120"
      >
        <div>
          <p class="mb-2">No automation in this list</p>
        </div>
      </v-card>
      <v-card
        class="px-2 py-5 mr-3 mb-2 mt-1 d-flex align-center justify-center rounded"
        width="320"
        height="120"
        @click="newRuleDialog = true"
      >
        <div>
          <div class="d-flex justify-center">
            <v-icon size="70" color="light-green lighten">
              mdi-plus
            </v-icon>
          </div>
          <p class="mb-2">Add a new Rule in this List</p>
        </div>
      </v-card>
    </div>
    <v-dialog v-model="newRuleDialog" width="900">
      <v-card>
        <v-card-title class="primarygrad">
          New Rule
          <v-spacer></v-spacer>
          <v-btn icon @click="newRuleDialog = false">
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="mt-3">
          <v-row class="pa-0 ma-0">
            <v-col class="d-flex">
              <p class="pr-2">When a</p>
              <v-autocomplete
                v-model="selectedEvent"
                class="mt-n4"
                label="Field"
                :items="allowedFields"
                auto-select-first
                solo-inverted
                flat
                @change="
                  targetValue = '';
                  selectedAction = '';
                  targetList = '';
                "
              />
            </v-col>
            <v-col class="d-flex" v-if="selectedEvent == 'dispoChange'">
              <p class="pr-2">Changes to</p>
              <v-combobox
                v-model="targetValue"
                class="mt-n4"
                label="Value"
                :items="finalVal"
                item-text="title"
                solo-inverted
                flat
                item-value="id"
                auto-select-first
                @change="removeTargetDispo"
              />
            </v-col>
          </v-row>
          <v-row class="pa-0 ma-0">
            <v-col class="d-flex">
              <p class="pr-2">Perform</p>
              <v-autocomplete
                v-model="selectedAction"
                class="mt-n4"
                label="Action"
                :items="actionsFields"
                solo-inverted
                flat
                auto-select-first
              />
            </v-col>
            <v-col v-if="selectedAction == 'changeList'" class="d-flex">
              <p class="pr-2">to</p>
              <v-autocomplete
                v-model="targetList"
                class="mt-n4"
                label="List"
                :items="allowedLists"
                solo-inverted
                flat
                item-text="name"
                item-value="id"
                auto-select-first
              >
                <template v-slot:no-data v-if="allowedLists.length < 1">
                  <div class="px-4">
                    You have only one list, Please add another
                  </div>
                </template>

                <template v-slot:no-data v-else>
                  <div class="px-4">No lists found</div>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col v-if="selectedAction == 'sendWhatsapp'" class="d-flex">
              <p class="pr-2">Template</p>
              <v-autocomplete
                v-model="template"
                class="mt-n4"
                label="Templates"
                :items="templates"
                solo-inverted
                flat
                item-text="name"
                item-value="id"
                return-object
                auto-select-first
              >
                <template v-slot:no-data v-if="templates.length <= 0">
                  <div class="px-4">
                    You have no templates, Please add a template
                  </div>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col v-if="selectedAction == 'sendVoiceBroadcast'" class="d-flex">
              <p class="pr-2">Audio</p>
              <v-autocomplete
                v-model="vbiAudio"
                class="mt-n4"
                label="Audios"
                :items="vbiAudios"
                solo-inverted
                flat
                item-text="fileName"
                item-value="id"
                return-object
                auto-select-first
              >
                <template v-slot:no-data v-if="vbiAudios.length <= 0">
                  <div class="px-4">
                    You have no audio, Please add a voice broadcast campaign for
                    audio
                  </div>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col v-if="selectedAction == 'changeStage'" class="d-flex">
              <p class="pr-2">to</p>
              <v-autocomplete
                v-model="targetStage"
                class="mt-n4"
                label="Select Stage"
                :items="allowedStages"
                solo-inverted
                flat
                item-text="name"
                item-value="id"
                auto-select-first
              />
            </v-col>

            <v-col v-if="selectedAction == 'callWebHook'" class="d-flex">
              <p class="pr-2">to</p>
              <v-flex>
                <v-text-field
                  v-model="targetWebhook"
                  class="mt-n3"
                  label="Webhook URL"
                  dense
                  outlined
                ></v-text-field>
              </v-flex>
            </v-col>

            <v-col v-if="selectedAction == 'interaktEvent'" class="d-flex">
              <p class="pr-2">Events</p>
              <v-autocomplete
                v-model="targetEventInterakt"
                class="mt-n4"
                label="Select Event"
                :items="allowedEvents"
                solo-inverted
                flat
                item-text="name"
                item-value="name"
                auto-select-first
              />
            </v-col>

            <v-col v-if="selectedAction == 'notificationEvent'" class="d-flex">
              <p class="pr-2">User</p>
              <v-autocomplete
                v-model="targetEventNotification"
                class="mt-n4"
                label="Select Event"
                :items="accounts"
                solo-inverted
                flat
                item-text="name"
                item-value="name"
                auto-select-first
                return-object
              />

              <template v-slot:no-data v-if="templates.length <= 0">
                <div class="px-4">
                  You have no templates, Please add a template
                </div>
              </template>
            </v-col>

            <v-col v-if="targetList != ''" class="d-flex">
              <p class="pr-2">Update value to</p>
              <v-combobox
                v-model="changeDispoTo"
                class="mt-n4"
                label="Value"
                :items="targetDispose"
                solo-inverted
                flat
                item-text="title"
                item-value="id"
                auto-select-first
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text color="red" @click="newRuleDialog = false">
            Cancel
          </v-btn>
          <v-btn depressed color="primarygrad" @click="saveRuleList">
            Save Rule
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import fb from "../js/firebase";
export default {
  props: {
    list: Object,
    allLists: Array,
    pageName: String,
  },
  data: () => ({
    vbiAudio: {},
    vbiAudios: [],
    automations: [],
    templates: [],
    targetEventWhatsapp: "",
    actions: [
      { text: "List Change", value: "changeList" },
      { text: "Stage Change", value: "changeStage" },
      { text: "Call Web Hook", value: "callWebHook" },
      { text: "Send Notification", value: "notificationEvent" },
      { text: "Send Whatsapp", value: "sendWhatsapp" },
      // { text: "Send Voice Broadcast", value: "sendBroadcast" },
    ],
    allowedKey: "",
    allowedStages: [],
    targetStage: "",
    allowedEvents: [],
    targetEventInterakt: "",
    targetEventNotification: "",
    targetWebhook: "",
    newRuleDialog: false,
    selectedEvent: "",
    targetValue: "",
    targetList: "",
    template: {},
    selectedAction: "",
    targetDispose: [],
    dispoField: [
      { text: "Contact's Disposition", value: "dispoChange" },
      { text: "Contact Is Created", value: "contactCreated" },
    ],
    changeDispoTo: {},
    interakt: {
      type: "interakt",
      config: {
        key: "",
        events: [],
      },
      events: [],
      companyId: "",
      enabled: false,
    },
  }),

  computed: {
    ...mapGetters([
      "selectedTeam",
      "ENDPOINT",
      "teams",
      "selectedList",
      "dispoList",
      "companyDetails",
    ]),
    allowedFields() {
      console.log("this.dispoField", this.dispoField);
      return this.dispoField;
    },
    actionsFields() {
      if (this.selectedEvent == "contactCreated") {
        return this.actions.filter(
          (item) => item.value === "notificationEvent"
        );
      } else if (this.selectedEvent == "dispoChange") {
        return this.actions.filter(
          (item) => item.value !== "notificationEvent"
        );
      } else {
        if (this.interakt.enabled) {
          return this.actions;
        } else {
          return this.actions.filter(
            (action) => action.value !== "interaktEvent"
          );
        }
      }
    },
    finalVal() {
      if (this.selectedEvent == "dispoChange") {
        return _.filter(this.dispoList, (d) => {
          return (
            d.title != "NEW" &&
            d.title != "SMS" &&
            d.title != "EMAIL" &&
            d.title != "LINK" &&
            d.title != "APP"
          );
        });
      } else if (this.selectedEvent == "assigneeChange") {
        return this.members;
      } else {
        return [];
      }
    },
    allowedLists() {
      return this.allLists.filter((f) => f != this.list);
    },
  },
  async created() {
    this.loadAutomations();

    this.setDispos();

    var listId = this.list.id;
    var response = await this.$http.get(
      `${this.ENDPOINT}/lists/${listId}/stages`
    );
    this.allowedStages = response.body;

    var resp = await this.$http.get(
      `${this.ENDPOINT}/integrations?filter[where][companyId]=${this.companyDetails.id}&filter[where][type]=interakt`
    );

    if (resp.body.length > 0) {
      this.interakt = resp.body[0];
      this.allowedEvents = resp.body[0].config.events;
      this.allowedKey = resp.body[0].config.key;
    }

    var resp2 = await this.$http.get(
      `${this.ENDPOINT}/integrations?filter[where][companyId]=${this.companyDetails.id}&filter[where][type]=whatsapp`
    );

    if (resp2.body.length > 0) {
      if (resp2.body[0].enabled == true) {
        if (this.pageName == "Whatsapp_Automation") {
          this.actions = [];
          this.dispoField = [];

          this.dispoField.push({
            text: "Contact's Disposition",
            value: "dispoChange",
          });
          this.actions.push({ text: "Send Whatsapp", value: "sendWhatsapp" });
        }

        this.getTemplates();
      }
    }

    // var respVB = await this.$http.get(
    //   `${this.ENDPOINT}/voiceBroadcasts?filter[where][companyId]=${this.companyDetails.id}&filter[include]=audio`
    // );

    // var dataVB = respVB.data;

    // this.vbiCampaigns = dataVB.filter((obj) => obj.audio && obj.audio.approved);

    // console.log("this.vbiCampaigns", JSON.stringify(this.vbiCampaigns));

    const respAudio = await this.$http.get(
      `${this.ENDPOINT}/audioLists?filter[where][companyId]=${this.companyDetails.id}`
    );
    console.log("Audio List:", respAudio.body);
    this.vbiAudios = respAudio.body.filter((audio) => audio.approved === true);

    if (
      this.companyDetails &&
      this.companyDetails.kycDetails &&
      this.companyDetails.kycDetails.kycVerified
    ) {
      if (this.vbiAudios.length > 0) {
        this.actions.push({
          text: "Send Voice Broadcast",
          value: "sendVoiceBroadcast",
        });
      }
    }

    this.getUsersList();
  },
  methods: {
    async loadAutomations() {
      try {
        const response = await this.$http.get(
          `${this.ENDPOINT}/automations?filter[where][listId]=${this.list.id}`
        );
        this.automations = response.body;
      } catch (e) {
        console.error("Error loading automations:", e);
      }
    },
    getTeamIds() {
      var teams = [];
      if (this.selectedTeam == "allTeams") {
        teams = _.map(this.teams, "id");
      } else {
        teams.push(this.selectedTeam);
      }
      teams = _.without(teams, "allTeams");
      return teams;
    },
    /** Fetch the accounts based on teamsId */
    async getUsersList() {
      this.loader = true;
      this.accounts = [];
      // Teams sections
      var teams = this.getTeamIds();

      try {
        var response = await this.$http.post(
          `${this.$store.state.ENDPOINT}/accounts/fetch`,
          {
            teams: teams,
          }
        );
        response = response.body;
        this.accounts = response;

        console.log("this.accounts", this.accounts);

        if (
          this.$store.getters.user.role == "Manager" ||
          this.$store.getters.user.role == "Teammanager"
        ) {
          this.accounts.unshift({ id: "allAccounts", name: "All Members" });
        }
        if (
          this.selectedAccount == "" &&
          this.accounts &&
          this.accounts.length > 0
        ) {
          return (this.selectedAccount = this.accounts[0].id);
        }
      } catch (e) {
        this.loader = false;
        this.$swal({ type: "error", text: "Something went wrong" });
      }
    },
    refresh() {
      console.log("Refresh in automation Rules");
      this.loadAutomations();
      this.$emit("refresh");
    },
    getTemplates() {
      this.preloader = true;
      this.templates = [];
      // Return the Promise chain
      return this.$http
        .get(this.$store.state.ENDPOINT + "/whatsapps/getTemplates")
        .then((response) => {
          this.preloader = false;
          response = response.body;
          if (response && response.data && Array.isArray(response.data)) {
            this.templates = _.filter(response.data, { status: "APPROVED" });
            console.log("Loaded templates:", this.templates);
          } else {
            // throw new Error(
            //   "Invalid response format while loading the templates."
            // );
          }
        })
        .catch((error) => {
          this.preloader = false;
          console.error("Failed to load templates:", error);
          this.$swal({
            type: "error",
            text:
              "Something went wrong while loading the templates: " +
              error.message,
          });
          throw error; // Rethrow to keep the error propagating
        })
        .finally(() => {
          this.preloader = false; // Ensure preloader is turned off
        });
    },
    setDispos() {
      // Set Target Dispos
      _.map(this.dispoList, (d) => {
        if (
          d.title != "SMS" &&
          d.title != "EMAIL" &&
          d.title != "LINK" &&
          d.title != "APP"
        ) {
          this.targetDispose.push({
            id: d.id,
            title: d.title,
          });
        }
      });

      console.log(" this.targetDispose", this.targetDispose);

      this.changeDispoTo = { id: "sameAs", title: "Same As Before" };
      this.targetDispose.unshift({ id: "rechurn", title: "Rechurn" });
      this.targetDispose.unshift(this.changeDispoTo);
    },
    removeTargetDispo(e) {
      this.targetDispose = _.filter(this.targetDispose, (d) => {
        return d.title != e.title;
      });
    },
    deleteRule(id) {
      this.$swal({
        title: "Are you sure?",
        text: "Remove this Rule?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1bca3f",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Remove it!",
      })
        .then(async (willDelete) => {
          if (willDelete.value) {
            try {
              await this.$http.delete(`${this.ENDPOINT}/automations/${id}`);
              this.$swal({ type: "success", text: "Successful" });
              fb.log("remove_automation");
              this.refresh();
            } catch (err) {
              console.log("Error deleting rule:", err);
              this.$swal({ type: "error", text: err.body.error.message });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async saveRuleList() {
      const self = this;

      if (self.selectedEvent == "" || self.selectedAction == "") {
        return self.$swal({ type: "warning", text: "Please fill all fields" });
      }

      if (self.selectedEvent == "dispoChange" && self.targetValue == "") {
        self.$swal({
          type: "warning",
          text: `Please fill all fields`,
        });
        return;
      }

      if (self.selectedAction == "changeList" && self.targetList == "") {
        return self.$swal({ type: "warning", text: "Please select a list" });
      }

      if (self.selectedAction == "changeStage" && self.targetStage == "") {
        return self.$swal({ type: "warning", text: "Please select a stage" });
      }

      if (
        self.selectedAction == "interaktEvent" &&
        self.targetEventInterakt == ""
      ) {
        return self.$swal({ type: "warning", text: "Please select events" });
      }

      if (
        self.selectedAction == "notificationEvent" &&
        self.targetEventNotification == ""
      ) {
        return self.$swal({ type: "warning", text: "Please select user" });
      }

      var payload = { action: self.selectedAction };

      if (self.selectedEvent.substr(0, 5) == "field") {
        payload.event = "fieldChange";
        payload.targetField = self.selectedEvent.substr(
          self.selectedEvent.indexOf("=") + 1
        );
      } else {
        payload.event = self.selectedEvent;
      }

      if (typeof self.targetValue == "object") {
        payload.targetValue = self.targetValue.title;
      } else {
        payload.targetValue = self.targetValue;
      }

      if (self.changeDispoTo.id != "sameAs") {
        payload.changeDispoTo = self.changeDispoTo;
      }

      if (self.selectedAction == "changeList") {
        var res = await self.$http.get(
          `${self.ENDPOINT}/lists/${self.targetList}?filter[fields][id]=true&filter[fields][name]=true`
        );
        payload.actionChange = res.body;
      }

      if (self.selectedAction == "changeStage") {
        var resStage = await self.$http.get(
          `${self.ENDPOINT}/stages/${self.targetStage}?filter[fields][id]=true&filter[fields][name]=true`
        );
        payload.actionChange = resStage.body;
      }

      if (self.selectedAction == "interaktEvent") {
        payload.actionChange = {
          name: self.targetEventInterakt,
          key: self.allowedKey,
        };
      }

      if (self.selectedAction == "notificationEvent") {
        payload.actionChange = {
          name: self.targetEventNotification.name,
          key: self.targetEventNotification.id,
        };
      }

      function validURL(url) {
        var re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;
        if (!re.test(url)) {
          return false;
        }
        return true;
      }

      if (self.selectedAction == "callWebHook") {
        if (!validURL(self.targetWebhook)) {
          return self.$swal({
            type: "warning",
            text: "Please enter a valid URL",
          });
        } else {
          payload.actionChange = {
            id: self.targetWebhook,
            url: self.targetWebhook,
          };
        }
      }

      if (self.selectedAction == "sendWhatsapp") {
        payload.actionChange = {
          template: self.template,
          key: self.allowedKey,
        };
      }

      if (self.selectedAction == "sendVoiceBroadcast") {
        payload.actionChange = {
          audio: self.vbiAudio,
          key: self.$store.state.user.id,
        };
      }

      // Check for duplicate rules
      var ruleExists = this.automations.some((rule) => {
        return (
          rule.event === payload.event &&
          rule.targetValue === payload.targetValue &&
          rule.action === payload.action &&
          JSON.stringify(rule.actionChange) ===
            JSON.stringify(payload.actionChange)
        );
      });

      if (ruleExists) {
        return self.$swal({ text: "Rule Already Present", type: "warning" });
      }

      try {
        await self.$http.post(`${self.ENDPOINT}/automations`, {
          ...payload,
          listId: self.list.id,
        });
        fb.log("add_automation");
        this.$swal({ type: "success", text: "Automation Rule set" });
        self.newRuleDialog = false;
        this.refresh();
      } catch (e) {
        console.log("Error in saving automation rule", e);
      }
    },
  },
};
</script>

<style>
.flash {
  position: absolute;
  top: -30px;
  right: -55px;
  transform: rotate(25deg);
}

.flash__icon {
  color: rgba(179, 179, 179, 0.438) !important;
}

.live {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: rgb(95, 255, 47);
  right: 10px;
  top: 10px;
}
</style>
